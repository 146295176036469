<template>
  <div>
    <div class="center_logo" v-if="$store.state">
      <img
        class="logo"
        :src="
          'assets/' +
          ($store.state.theme ? $store.state.theme.logoAsset : 'logo2.png')
        "
      />

      <StartButton text="Jetzt anhören" @click="goToSpotify"></StartButton>
      <StartButton text="Minigame spielen" @click="goToGame"></StartButton>
      <StartButton text="Tickets" @click="goToTickets"></StartButton>

      <!-- <div class="text_button" @click="goToAuth">
        {{
          $store.state.authed
            ? "Welcome, " + $store.state.currentUser.displayName
            : "Login or Register"
        }}
      </div> -->
    </div>

    <div class="leftBottom">
      <img
        class="logo"
        style="max-width: 150px !important; padding: 10px"
        :src="'assets/' + 'ennio.png'"
      />
    </div>
  </div>
</template>

<script>
import StartButton from "../components/ui/StartButton.vue";

export default {
  name: "Home",
  components: {
    StartButton,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goToGame() {
      this.$router.push("/menu");
    },

    goToSpotify() {
      this.$store.state.audio.toggleBgMute();
      const url = "https://orcd.co/rimini";
      window.open(url, "_blank");
    },
    goToTickets() {
      this.$store.state.audio.toggleBgMute();
      const url = "https://krasserstoff.com/ennio";
      window.open(url, "_blank");
    },
    goToAuth() {
      this.$router.push("/account");
    },
  },
};
</script>

<style scoped>
.leftBottom {
  position: fixed;
  left: 5px;
  bottom: 5px;
  opacity: 0.3;
}

@media only screen and (max-width: 1000px) {
  .leftBottom {
    display: none;
  }
}
</style>
