// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/Lato/Lato-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/TitanOne/TitanOne-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n@font-face {\n  font-family: \"Lato\";\n  src: local(\"Lato\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TitanOne\";\n  src: local(\"TitanOne\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n.fade-enter-active,\n.fade-leave-active {\n  transition: 0.5s;\n}\n.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {\n  opacity: 0;\n}\n.routerView {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  overflow-y: scroll;\n}\n.maintenance_logo {\n  margin-top: 50px;\n  max-width: 150px;\n  opacity: 0.5;\n}\n.center a {\n  color: rgb(127, 255, 255);\n  text-decoration: underline;\n}\n", ""]);
// Exports
module.exports = exports;
