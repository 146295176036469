import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/functions";
import "firebase/auth";
import "firebase/performance";
import "firebase/remote-config";

// firebase init goes here
const config = {
  apiKey: "AIzaSyBOikTR8j9UYM_ERwXOxQ6nk8Ha-uw6iWs",
  authDomain: "taptap-c8dfa.firebaseapp.com",
  databaseURL: "https://taptap-c8dfa-default-rtdb.firebaseio.com/",
  projectId: "taptap-c8dfa",
  storageBucket: "taptap-c8dfa.appspot.com",
  messagingSenderId: "119320577946",
  appId: "1:119320577946:web:44bc1773578c6f69141c23",
  measurementId: "G-EVWJJ5HBGW",
};
firebase.initializeApp(config);

// Init analytics
firebase.analytics();

// firebase utils
const db = firebase.firestore();
const firestore = firebase.firestore; //db instance
const auth = firebase.auth();
const functions = firebase.functions();
const analytics = firebase.analytics;
const currentUser = auth.currentUser;
const remoteConfig = firebase.remoteConfig();

remoteConfig.settings.minimumFetchIntervalMillis = 10000;

// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance();

// firebase collections
const usersCollection = db.collection("users");
const songsCollection = db.collection("songs");
const sheetsCollection = db.collection("sheets");
const resultsCollection = db.collection("results");
const tagsCollection = db.collection("tags");
const playsCollection = db.collection("plays");
const playlistsCollection = db.collection("playlists");

export {
  db,
  firestore,
  analytics,
  auth,
  currentUser,
  perf,
  functions,
  usersCollection,
  songsCollection,
  sheetsCollection,
  resultsCollection,
  tagsCollection,
  playsCollection,
  playlistsCollection,
  remoteConfig,
};
