<template>
  <div>
   

    <PageBackground
      songSrc="/assets/sdscene.mp3"
      imageSrc="black"
      :showNav="false"
    ></PageBackground>

    <video
      autoplay
      muted
      playsinline
      preload="metadata"
      loop
      class="bgVid"
      src="https://res.cloudinary.com/dsa2lnuw3/video/upload/v1681148267/Trip%20Tap%20Tap/lila_comp_ef2brm.mp4"
    >
   
      <source
        src="https://res.cloudinary.com/dsa2lnuw3/video/upload/v1681148267/Trip%20Tap%20Tap/lila_comp_ef2brm.mp4"
        type="video/mp4"
      />
      <source
        src="https://res.cloudinary.com/dsa2lnuw3/video/upload/v1681148267/Trip%20Tap%20Tap/lila_comp_ef2brm.webm"
        type="video/webm"
      />
    </video>
    <div class="center">
      <div class="gameover">Game Over</div>
      <div
        class="btn-action btn-dark"
        @click="replay"
        v-if="$route.params.sheetId"
      >
        <span>Nochmal</span>
      </div>
      <div class="btn-action btn-dark" @click="toMenu">
        <span>Weiter</span>
      </div>
    </div>
  </div>
</template>

<script>
import PageBackground from "../components/common/PageBackground.vue";

export default {
  name: "GameOverScreen",
  components: { PageBackground },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    this.$store.state.audio.playEffect("whoosh");
  },
  methods: {
    replay() {
      this.$router.push("/game/" + this.$route.params.sheetId);
    },
    toMenu() {
      this.exitGame();
    },
    exitGame(e, reason) {
      this.$router.push("/menu");
      console.log(e);
      console.log(reason);
    },
  },
};
</script>

<style scoped>
.bgVid {
  position: fixed;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  animation: 5s ease 0s normal forwards 1 fade;
}

.gameover {
  font-size: 5em;
  color: white;
  margin-bottom: 50px;
  width: 90vw;
  animation: fail_enter 1.5s ease;
}

.btn-dark {
  background-color: rgba(0, 0, 0, 0.384);
}
.btn-dark:hover {
  background-color: white;
}
.fa-icon {
  vertical-align: middle;
  margin-right: 5px;
}

@media screen and (max-width: 600px) {
  .gameover {
    font-size: 3.7em;
  }
  .bgVid {
    left: -150%;
    min-width: 200vw;
  }
}

@keyframes fail_enter {
  from {
    transform: scale(3);
    color: red;
    opacity: 0;
    text-shadow: none;
  }
  to {
    opacity: 1;
    color: white;
    transform: scale(1);
    text-shadow: 2px 2px 50px rgb(150, 0, 0);
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  22% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
