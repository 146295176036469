var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",class:{
    onScreen: _vm.instance.isWithinTime(_vm.note),
    current: _vm.index === _vm.instance.timeArr.length - _vm.instance.timeArrIdx - 1,
    editing: _vm.isEditing,
  }},[_c('div',{staticStyle:{"width":"10%"}},[_c('label',{staticClass:"cb_container cb_small"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.parent.selectedNotes),expression:"parent.selectedNotes"}],attrs:{"type":"checkbox"},domProps:{"value":_vm.note,"checked":Array.isArray(_vm.parent.selectedNotes)?_vm._i(_vm.parent.selectedNotes,_vm.note)>-1:(_vm.parent.selectedNotes)},on:{"change":function($event){var $$a=_vm.parent.selectedNotes,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.note,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.parent, "selectedNotes", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.parent, "selectedNotes", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.parent, "selectedNotes", $$c)}}}}),_vm._v(" "),_c('span',{staticClass:"checkmark"})])]),_vm._v(" "),_c('div',{staticClass:"time",staticStyle:{"width":"25%"},on:{"click":function($event){return _vm.seek(_vm.note)}}},[_vm._v("\n    "+_vm._s(_vm.note.t)+"\n  ")]),_vm._v(" "),_c('div',{staticStyle:{"width":"55%"}},[_c('div',{staticClass:"keyWrapper",on:{"click":function($event){return _vm.edit(_vm.note)}}},_vm._l((_vm.instance.trackKeyBind),function(k){return _c('div',{key:k,class:{
          activeNote: _vm.note.k.includes(k),
          holdNote: _vm.note.h && _vm.note.h[k],
        }},[_vm._v("\n        "+_vm._s(k === " " ? "-" : k)+"\n      ")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }