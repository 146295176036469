<template>
  <div class="warpper">
    <transition name="slide-fade" mode="out-in">
      <div class="tutorial flex_hori" key="2" v-if="slide == 1">
        <div class="texts">
          <div class="title">Anleitung</div>
          <div>
            Jede Spur hat eine zugehörige Tastenbindung. Für ein Spiel mit 4
            Spuren, entsprechen die Tasten D, F, J und K den Spuren 1, 2, 3 und
            4.
          </div>
          <img class="logo" :src="img.a" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const timeline = [
  { time: 1, slide: 1 },
  { time: 5, slide: 2 },
  { time: 10, slide: 3 },
];

export default {
  name: "Tutorial",
  data() {
    return {
      slide: 0,
      timer: null,
      idx: 0,
      img: {
        logo: null,
        a: null,
        b: null,
        c: null,
      },
    };
  },
  async mounted() {
    this.timer = setInterval(() => {
      if (this.time >= timeline[this.idx].time) {
        this.slide = timeline[this.idx++].slide;
        if (this.slide == -1)
          this.$parent.$refs.zoom.show("Mach dich bereit...");
      } else if (this.idx != 0 && this.time < timeline[this.idx - 1].time) {
        this.idx = 0;
        this.slide = 0;
      }
    }, 50);

    this.img.logo = await this.loadImage("/assets/logo_white.png");
    this.img.a = await this.loadImage("/assets/tutorial/2.png");
    this.img.b = await this.loadImage("/assets/tutorial/1.png");
    this.img.c = await this.loadImage("/assets/tutorial/3.png");
  },
  computed: {
    time() {
      return this.$parent.instance.playTime;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async loadImage(url) {
      return new Promise((resolve) => {
        let img = new Image();
        img.src = url;
        img.onload = () => {
          resolve(img.src);
        };
      });
    },
    skip() {
      this.$parent.instance.seekTo(timeline[this.idx].time);
    },
  },
};
</script>

<style scoped>
.tutorial {
  margin: auto;
  background: rgba(0, 0, 0, 0);
  padding: 45px 30px;
  width: 100%;
  max-width: 500px;
  z-index: 1000;
  transition: background 1s;
  box-sizing: border-box;
}

.logo {
  width: 80%;
  margin: auto;
  text-align: center;
  display: block;
  padding: 10px 0;
}

.warpper {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-60%);
}

.texts {
  text-align: left;
}

.artist {
  text-align: center;
}

.artist_hr {
  width: 20%;
  margin-top: 15px;
  border: 1px solid white;
}

.text_button {
  padding: 10px;
  text-align: center;
}

.title {
  font-size: 1.4em;
  padding: 10px 0;
  text-align: center;
}

.emoji {
  font-size: 3em;
  text-align: center;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
</style>
